
import { observable, action, computed, autorun } from 'mobx';

class Global {
    public static selfClassName = 'Global';

    @observable test = 9999;

    @action Add = () => {
        console.log(123);
        this.test++;
    }
}

export default Global;
