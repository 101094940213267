import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/buyerCenter',
        name: 'buyerCenter',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-buyerCenter" */ '@@/views/modules/buyerCenter')),
        meta: {
            title: '买家中心',
            intercept: intercept.route
        }
    },
    {
        path: '/buyerCenter/requireDetail',
        name: 'buyerCenter.requireDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-requireDetail" */ '@@/views/modules/sellerCenter/modules/requireDetail')),
        meta: {
            title: '需求详情',
            intercept: intercept.route
        }
    },
    {
        path: '/buyerCenter/purchasedApiDetail',
        name: 'buyerCenter.purchasedApiDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-requireDetail" */ '@@/views/modules/buyerCenter/components/buyerProduct/apiProductDetail')),
        meta: {
            title: '已购产品（api）',
            intercept: intercept.route
        }
    },
    {
        path: '/buyerCenter/purchasedDataPacketDetail',
        name: 'buyerCenter.purchasedDataPacketDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-requireDetail" */ '@@/views/modules/buyerCenter/components/buyerProduct/dataPacketDetail')),
        meta: {
            title: '已购产品（数据包）',
            intercept: intercept.route
        }
    },
    {
        path: '/buyerCenter/buyerProductDetail',
        name: 'buyerCenter.buyerProductDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-buyerProductDetail" */ '@@/views/modules/buyerCenter/components/buyerProduct/buyerProductDetail')),
        meta: {
            title: '已购产品',
            intercept: intercept.route
        }
    },
    {
        path: '/buyerCenter/dataPacketProductDetail',
        name: 'dataServiceProvider.dataPacketProductDetail',
        // component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider-dataPacketProductDetail" */ '@@/views/modules/dataServiceProvider/modules/dataProductDetail/dataPacketDetail')),
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider-dataPacketProductDetail" */ '@@/views/modules/buyerCenter/components/buyerProduct/buyerProductDetail')),
        meta: {
            title: '数据产品详情(数据包)',
            intercept: intercept.route
        }
    },
    {
        path: '/buyerCenter/apiProductDetail',
        name: 'dataServiceProvider.apiProductDetail',
        // component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider-apiProductDetail" */ '@@/views/modules/dataServiceProvider/modules/dataProductDetail/apiProductDetail')),
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider-apiProductDetail" */ '@@/views/modules/buyerCenter/components/buyerProduct/buyerProductDetail')),
        meta: {
            title: '数据产品详情(API)',
            intercept: intercept.route
        }
    }
];
export default _Route;
