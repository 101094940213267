import React from 'react';
import intercept from '@@/intercept';
import {IRouteItemConf} from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/makeCenter',
        name: 'makeCenter',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-sellerCenter" */ '@@/views/modules/sellerCenter')),
        meta: {
            title: '社会加工单位中心',
            intercept: intercept.route
        }
    }
];
export default _Route;
