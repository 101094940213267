/* eslint-disable linebreak-style */
import React, { useEffect, useState, useImperativeHandle, forwardRef, useMemo } from 'react';
import { withRouter } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import './index.scoped.scss';
import userIcon from '@@/assets/images/login-icon.png';
import arrowupIcon from '@@/assets/images/arrowup-outlined.png';
import { Badge, Empty, Popover } from 'antd';
import { BellOutlined, DatabaseOutlined, FileDoneOutlined, PoweroffOutlined, SolutionOutlined, UserAddOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { stores } from '@@/store';
import { login, logout } from '@@services/user.service';
import { useLocalStore } from 'mobx-react';
import icon1 from '@@/assets/images/userCenter/icon-1.png';
import _icon1 from '@@/assets/images/msgCenter/icon-1.png';
import { getMessageList, getNoReadCount as getNoReadCountApi, getMessageAllRead as getMessageAllReadApi } from '@@/services/message.service';
import dayjs from 'dayjs';
import { toMsgInfo } from '@common/common_view/msgSocket';

const MsgPop = (props: any) => {
    const history = useHistory();
    const [msgList, setMsgList] = useState<any[]>([]);
    const [msgCount, setMsgCount] = useState<any>({});

    useEffect(() => {
        if (!stores.UserInfoTable.localStore.token) return;
        getNoReadCount();
    }, []);

    const getMsgList = async () => {
        if (!stores.UserInfoTable.localStore.token) return;
        const params: any = {
            startPage: 1,
            pageSize: 10,
            type: '4',
            readState: 0
        };
        const [err, res] = await getMessageList(params);
        if (err) return;
        res.records.forEach((i: any) => {
            i.sendTime = dayjs(i.sendTime).format('YYYY-MM-DD HH:mm:ss');
        });
        setMsgList(res.records);
    };

    const getMessageAllRead = async () => {
        await getMessageAllReadApi();
        getNoReadCount();
    };

    const getNoReadCount = async () => {
        const [err, res] = await getNoReadCountApi({ type: 4 });
        if (err) return;
        setMsgCount(res);
    };

    return (
        <Popover
            overlayClassName="msg-pop"
            arrow={false}
            content={
                <div className="msg-content">
                    <div className="header flex justify-between items-center">
                        <span>消息</span>
                        {/* <span>清空</span> */}
                    </div>
                    {
                        msgList.length > 0 ? (
                            <ul className="list overflow-y-scroll max-h-[330px]">
                                {
                                    msgList.map((item, index) => (
                                        <li
                                            className="flex justify-start items-center"
                                            key={index}
                                            onClick={() => toMsgInfo(item, history)}
                                        >
                                            <img className="left" src={icon1} />
                                            <div className="right">
                                                <h4>{item.messageName}</h4>
                                                <div
                                                    className="overflow-hidden whitespace-nowrap overflow-ellipsis"
                                                    style={{ maxWidth: 380 }}
                                                >
                                                    {item.messageContent}
                                                </div>
                                                <div>{item.sendTime}</div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        ) : (
                            <Empty className="no-content" image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无消息" />
                        )
                    }
                    <div className="footer flex justify-between items-center">
                        <span onClick={getMessageAllRead}>全部已读</span>
                        <Link
                            className="flex items-center"
                            to={`/userCenter?tabActive=3`}
                        >
                            查看更多
                            <img className="open-icon" src={_icon1} />
                        </Link>
                    </div>
                </div>
            }
            onOpenChange={(open) => {
                if (open) getMsgList();
            }}
        >
            <Badge className="msg" count={msgCount?.marketTypeCount}>
                <BellOutlined className={`msg-icon ${(props?.obvious || props?.path !== '/') && 'obvious'}`} />
            </Badge>
        </Popover>
    );
};

const Navbar = (props: any, ref: any) => {
    const history = useHistory();
    const path = history.location.pathname;
    const user = stores.UserInfoTable.localStore.user;

    const [obvious, setObvious] = useState(false);

    useImperativeHandle(ref, () => ({
        setObvious
    }));

    const userCenter = useMemo(() => {
        const list: any = [
            { title: '买家中心', icon: <UserAddOutlined style={{ color: '#666' }} />, url: '/buyerCenter' },
            { title: '个人中心', icon: <DatabaseOutlined style={{ color: '#666' }} />, url: '/userCenter' },
            { title: '卖家中心', icon: <UsergroupAddOutlined style={{ color: '#666' }} />, url: '/sellerCenter' },
            // { title: '授权运营单位中心', icon: <FileDoneOutlined style={{ color: '#666' }} />, url: '/makeCenter' },
            // { title: '社会加工单位中心', icon: <CloudServerOutlined style={{ color: '#666' }} />, url: '/supplierCenter' },
            // { title: '订单中心', icon: <FileDoneOutlined style={{ color: '#666' }} />, url: '/order' },
        ];
        if (user.isAdmin === 1 && user.organizationType) {
            list.push({ title: '测试场景入驻', icon: <FileDoneOutlined style={{ color: '#666' }} />, url: '/testEnvironmentApproveList' });
            list.push({ title: '企业管理', org: true, url: '/organizationDetail' });
        }
        return list;
    }, [user]);

    const routers: any[] = [
        { name: '首页', url: '/' },
        { name: '数据产品', url: '/dataProductMarket' },
        // { name: '领域专区', url: '/bbb' },
        { name: '需求大厅', url: '/demandPlatform/introductionPage' },
        { name: '资源目录', url: '/ddd' },
        // { name: '数据生态', url: '/eee' },
        { name: '数商入驻', url: '/guide', needLogin: true, hidden: user.isAdmin !== 1 },
        { name: '数据实验室', url: '/dataLaboratory' }
    ];

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setObvious(document.documentElement.scrollTop > 80);
        });
    }, []);

    return (
        <div className={`navbar ${(obvious || path !== '/') && 'obvious'}`}>
            <div className="layout flexbt">
                <div className="logo" onClick={() => history.push({ pathname: '/' })} />
                <div className="flexbt" style={{ height: '100%' }}>
                    <ul className={`routers flexst ${path === '/' ? 'isHome' : ''}`}>
                        {routers.map((item, index) =>
                            !item.hidden ? (
                                <li
                                    key={index}
                                    className={item.url === '/' && path === item.url && obvious ? 'current' : item.url !== '/' && path.indexOf(item.url) === 0 ? 'current' : ''}
                                    onClick={() => {
                                        if (!['首页', '数据产品', '数据实验室', '数商入驻', '需求大厅'].includes(item.name)) return;
                                        item.needLogin ? login(item.url) : history.push({ pathname: item.url });
                                    }}
                                >
                                    {item.name}
                                </li>
                            ) : null
                        )}
                    </ul>
                    {/* <MsgPop path={path} obvious={obvious} /> */}
                    <div className="user">
                        {stores.UserInfoTable.localStore.token ? (
                            <Popover
                                overlayClassName="pop"
                                content={
                                    <ul className="user-center">
                                        {userCenter.map((item, index) => (
                                            <li key={index} className={`flexbt ${path === item.url ? 'current' : ''}`} onClick={() => login(item.url)}>
                                                <div className="flexst">
                                                    {
                                                        item.icon && <div className="icon-box flexct">{item.icon}</div>
                                                    }
                                                    {
                                                        item.org && <div className="icon-box flexct">
                                                            <div className="org" />
                                                        </div>
                                                    }
                                                    <div>
                                                        <div className="title">{item.title}</div>
                                                        <span className="look">查看详情</span>
                                                    </div>
                                                </div>
                                                <img className="arrowup-icon" src={arrowupIcon} />
                                            </li>
                                        ))}
                                        <div className="login-out" onClick={logout}>
                                            <PoweroffOutlined /> <b>退出</b>
                                        </div>
                                    </ul>
                                }
                            >
                                <div className="flexst" onClick={() => login()}>
                                    <img className="user-icon" src={userIcon} />
                                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis" style={{ maxWidth: 40 }}>
                                        {user.name || user.username}
                                    </span>
                                </div>
                            </Popover>
                        ) : (
                            <div className="flexst" onClick={() => login()}>
                                <img className="user-icon" src={userIcon} />
                                <span className="overflow-hidden whitespace-nowrap overflow-ellipsis" style={{ maxWidth: 40 }}>
                                    登录
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(Navbar);
