import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/dataProductMarket',
        name: 'dataProductMarket',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataProductMarket" */ '@@/views/modules/dataProductMarket')),
        meta: {
            title: '数据产品',
            intercept: intercept.route
        }
    },
    {
        path: '/dataProductMarket/apiProductDetail',
        name: 'dataServiceProvider.apiProductDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider-apiProductDetail" */ '@@/views/modules/dataServiceProvider/modules/dataProductDetail/apiProductDetail')),
        meta: {
            title: '数据产品详情(API)',
            intercept: intercept.route
        }
    },
    {
        path: '/dataProductMarket/dataPacketProductDetail',
        name: 'dataServiceProvider.dataPacketProductDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider-dataPacketProductDetail" */ '@@/views/modules/dataServiceProvider/modules/dataProductDetail/dataPacketDetail')),
        meta: {
            title: '数据产品详情(数据包)',
            intercept: intercept.route
        }
    },
    {
        path: '/dataProductMarket/otherProductDetail',
        name: 'dataServiceProvider.otherProductDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider-dataPacketProductDetail" */ '@@/views/modules/dataServiceProvider/modules/dataProductDetail/otherProductDetail')),
        meta: {
            title: '数据产品详情',
            intercept: intercept.route
        }
    },
    {
        path: '/dataProductMarket/resourceApply',
        name: 'dataProductMarket.resourceApply',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataProductMarket-resourceApply" */ '@@/views/modules/dataProductMarket/modules/resourceApply')),
        meta: {
            title: '资源申请',
            intercept: intercept.route
        }
    },
    {
        path: '/dataProductMarket/productTest',
        name: 'dataProductMarket.productTest',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataProductMarket-productTest" */ '@@/views/modules/dataProductMarket/modules/productTest')),
        meta: {
            title: '产品测试',
            intercept: intercept.route
        }
    }
];
export default _Route;
