import React from 'react';
import intercept from '@@/intercept';
import {IRouteItemConf} from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/sellerCenter',
        name: 'sellerCenter',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-sellerCenter" */ '@@/views/modules/sellerCenter')),
        meta: {
            title: '卖家中心',
            intercept: intercept.route
        }
    },
    {
        path: '/sellerCenter/requireDetail',
        name: 'sellerCenter.sellerCenter',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-sellerCenter" */ '@@/views/modules/sellerCenter/modules/requireDetail')),
        meta: {
            title: '需求详情',
            intercept: intercept.route
        }
    },
    {
        path: '/sellerCenter/shelfApplyFor',
        name: 'sellerCenter.shelfApplyFor',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-sellerCenter-shelfApplyFor" */ '@@/views/modules/sellerCenter/component/workbenchPage/modules/shelfApplyFor')),
        meta: {
            title: '新上架申请',
            intercept: intercept.route
        }
    },
];
export default _Route;
