/* eslint-disable linebreak-style */
/* 数商登记 */
import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
import { FileSearchOutlined } from '@ant-design/icons';
type IReactLazyType =
  | React.ComponentClass<{}, any>
  | React.FunctionComponent<{}>

const _Route: IRouteItemConf[] = [
    {
        path: '/registered',
        name: 'registered',
        component: React.lazy<IReactLazyType>(() =>
            import('@@/views/modules/registered/applySettlement')
        ),
        meta: {
            title: '数商入驻',
            intercept: intercept.route,
        },
    },
    {
        path: '/organizationDetail',
        name: 'organizationDetail',
        component: React.lazy<IReactLazyType>(() =>
            import('@@/views/modules/registered/organizationDetail')
        ),
        meta: {
            title: '企业管理',
            intercept: intercept.route,
        },
    },
];
export default _Route;
