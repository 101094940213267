import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/',
        name: 'home',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-home" */ '@@/views/modules/index')),
        meta: {
            title: '首页',
            intercept: intercept.route
        }
    }
];
export default _Route;
