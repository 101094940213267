import devHost from './dev.host.json';
import testHost from './test.host.json';
import test2Host from './test2.host.json';
import debugHost from './debug.host.json';
import debug2Host from './debug2.host.json';
import releaseHost from './release.host.json';
import { env } from '../../../../env';

const hostMap = {
    dev: devHost,
    test: testHost,
    test2: test2Host,
    debug: debugHost,
    debug2: debug2Host,
    release: releaseHost
};

const host =
    hostMap[
        env.appEnv as 'dev' | 'test' | 'test2' | 'debug' | 'debug2' | 'release'
    ];
export { host };
