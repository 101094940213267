import {intercept} from 'mobx';

export default (chunk:any, storeName:string) => {
    return intercept(chunk, 'localStore', (change: any): any => {
        if (change.type === 'update') {
            console.log('************');
            const newKey = `LOCALSTORE_${storeName.toUpperCase()}`;
            localStorage.setItem(newKey, JSON.stringify(change.newValue));
        }
        return change;
    });
}
