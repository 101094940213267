import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/login',
        name: 'login',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-login" */ '@@/views/modules/login')),
        meta: {
            title: '登录',
            intercept: intercept.route
        }
    },
    {
        path: '/guide',
        name: 'guide',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-guide" */ '@@/views/modules/login/guide')),
        meta: {
            title: '指南',
            intercept: intercept.route
        }
    },
    {
        path: '/registry',
        name: 'registry',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-registry" */ '@@/views/modules/login/registry')),
        meta: {
            title: '注册',
            intercept: intercept.route
        }
    }
];
export default _Route;
