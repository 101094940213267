import {action} from 'mobx';

class Base {

  public sourceLocalStoreData:any;

  @action update = (param:any) => {

      if (typeof param !== 'object') {
          return false;
      }

      if (param && param.localStore) {
          throw new Error('please use updateLocalStore method to modify localStore');
      } else {
          Object.keys(param).forEach((key) => {
              // @ts-ignore
              if (this['__proto__'].hasOwnProperty(key)) {
                  // @ts-ignore
                  this[key] = param[key]
              } else {
                  throw new Error(`${key} does not exist, is filtered`);
              }
          })
      }
  }

  @action updateLocalStore = (param:any) => {
      // @ts-ignore
      this.localStore = {...this.localStore, ...param}
  }

  @action cleanAllLocalStore = () => {
      // @ts-ignore
      this.localStore = this.sourceLocalStoreData;
  }

  initSyncLocalStorage(selfClassName:any, table:any, LOCAL_STORE:any) {

      // 如果table未设置localStore字段
      if (!table.localStore) {
          return false;
      }

      const localStoreKey = `LOCALSTORE_${selfClassName.toUpperCase()}`;
      const localStoreJsonStr = localStorage.getItem(localStoreKey);

      // console.log('init->localStoreJsonStr:', localStoreKey, localStoreJsonStr);

      let localStore = {};
      if (localStoreJsonStr) {
          localStore = JSON.parse(localStoreJsonStr);
      }
      if (LOCAL_STORE) {
          this.sourceLocalStoreData = LOCAL_STORE;
      }
      if (localStore && Object.keys(localStore).length > 0) {
          table.localStore = localStore
      } else {
          localStorage.setItem(localStoreKey, JSON.stringify(LOCAL_STORE));
      }
  }
}

export {Base}
