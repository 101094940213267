import './global.scss';
import './style.scoped.scss';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import Navbar from '@@/layout/admin/navbar';
import FooterBar from '@@/layout/admin/footerbar';
import { Breadcrumb, FloatButton } from 'antd';
import { IStore, stores } from '@@/store';
import { match, useHistory } from 'react-router-dom';
import { IRouteItemConf } from '@common/interface/route.interface';
import { observer } from 'mobx-react';
import { useMount, useSetState } from 'ahooks';
import { IMap } from '@common/services/api_d/base';

interface IProps {
    store?: IStore;
    match: match;
    location: Location;
    routeMap: IRouteItemConf[];
    [key: string]: any;
}

const AdminLayout: FC<IProps> = (props) => {
    const navbarRef = useRef<any>();
    const path = props.match.path;
    const [showFooter, setShowFooter] = useState<boolean>(false);
    const theme = stores.SiteInfoTable.localStore.theme;
    const history = useHistory();
    const [state, setState] = useSetState<{
        /** hideBreadcrumb */
        hideBreadcrumb: boolean;
    }>({
        hideBreadcrumb: false
    });

    useEffect(() => {
        setTimeout(() => {
            const fullpageDom: any = document.getElementsByClassName('fullpage_container')[0];
            if (!fullpageDom) return;
            // @ts-ignore
            const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
            const observer = new MutationObserver(function (mutations) {
                const current = fullpageDom.getAttribute('data-current');
                navbarRef.current.setObvious(() => current > 1);
                setShowFooter(() => current === '7');
            });
            observer.observe(fullpageDom, { attributes: true, attributeFilter: ['data-current'], attributeOldValue: true });
        });
    }, []);

    /** 构建面包削 */
    const getBreadcrumbItems = (): { title: ReactNode }[] => {
        const breadcrumbList = stores!.SystemTable!.breadcrumb;
        // 隐藏
        if (breadcrumbList.length > 0 && breadcrumbList[breadcrumbList.length - 1].meta.hideBreadcrumb) {
            setState({ hideBreadcrumb: true });
            return [];
        }
        const items = stores!.SystemTable!.breadcrumb.map((item: IMap, i: number) => {
            let title = item.meta.title;
            if (stores!.SystemTable!.breadcrumb.length === 2 && i === 0) {
                title = <a onClick={() => history.push(item.path)}>{item.meta.title}</a>;
            }
            // 自带跳转
            if (item.meta.breadcrumbLink) {
                title = <a onClick={() => history.push(item.path)}>{item.meta.title}</a>;
            }
            return { title };
        });
        items.unshift({ title: <a onClick={() => history.push('/')}>首页</a> });
        return items;
    };

    /** 构建当前面包削 */
    const createBreadcrumb = () => {
        let breadcrumbList: IRouteItemConf[] = [];
        const pathname = props.location.pathname;

        const loop = (pathname: string) => {
            props.routeMap.some((item) => {
                if (item.path === pathname) {
                    breadcrumbList.push(item);
                }
            });

            if (pathname.split('/').length > 1) {
                const prevPathname = pathname.substring(0, pathname.lastIndexOf('/'));
                loop(prevPathname);
            }
        };
        loop(pathname);
        breadcrumbList = breadcrumbList.reverse();
        return breadcrumbList;
    };

    /** 构建面包削 */
    useMount(() => {
        stores!.SystemTable!.createBreadcrumb(createBreadcrumb());
    });

    return (
        <div className={`adminLayout ${theme === 'dark' ? 'theme-dark' : ''}`}>
            <div className="mainWrap">
                <div>
                    {theme !== 'dark' && (
                        <div className="layout-fixed">
                            <Navbar ref={navbarRef} />
                        </div>
                    )}
                    <div className="main" style={{ paddingTop: path !== '/' && theme !== 'dark' ? 80 : 0 }}>
                        {location.pathname !== '/' && !state.hideBreadcrumb && <Breadcrumb className="page-content breadcrumb" items={getBreadcrumbItems()} />}
                        {props.children}
                    </div>
                </div>
                {(showFooter || location.pathname !== '/') && <FooterBar />}
            </div>
            <FloatButton.BackTop />
        </div>
    );
};

export default withRouter(observer(AdminLayout) as React.ComponentType<any>);
