import { stores } from '@@/store';
import { HttpService } from '@@/services/http.service';
import { host } from '@@config/host';

// 存储用户数据
export const setUserInfo = (userInfo: any) => {
    const {
        userid = '',
        contactName = '',
        companyName = '',
        phoneNum = '',
        username = '',
        email = '',
        expiresIn = '',
        name = '',
        roleName = '',
        organizationName = '',
        moduleList = null,
        roleOpAuth = '',
        organizationType = '',
        orgId = '',
        profilePhoto = null,
        organizationAddress = '',
        unifiedSocialCreditCode = '',
        organizationPrincipalEmail = '',
        isAdmin = '',
        loginType
    } = userInfo;

    stores.UserInfoTable!.updateLocalStore({
        token: userInfo.token || '',
        loginType,
        user: {
            userid,
            contactName,
            companyName,
            phoneNum,
            username,
            email,
            expiresIn,
            name,
            roleName,
            organizationName,
            moduleList,
            roleOpAuth,
            organizationType,
            orgId,
            profilePhoto,
            organizationAddress,
            unifiedSocialCreditCode,
            organizationPrincipalEmail,
            isAdmin
        }
    });
};

export const setSiteInfo = (siteInfo: { theme: string; area: string }) => {
    const { theme, area } = siteInfo;
    stores.SiteInfoTable!.updateLocalStore({
        area,
        theme
    });
};

// 账号登出
export const logout = () => {
    const user = stores.UserInfoTable.localStore;

    console.log('user:::', user);

    // 浙里办退出
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', 'https://portal.zjzwfw.gov.cn/uc/unifiedLogout');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('height', '0');
    document.body.appendChild(iframe);
    iframe.addEventListener(
        'load',
        async () => {
            const http = HttpService;
            await http('POST', `${host.SERVER_BASE_API}/usercenter/user/logout`, { userNo: user.userNo, loginType: user.loginType || 1 });
            setUserInfo({});
            setTimeout(() => {
                location.href = '/login';
            }, 3000);
        },
        true
    );
};

// 是否登录
export const isLogin = !!stores.UserInfoTable.localStore.user.username;

// 需要跳转
export const login = (goLink?: string) => {
    const isLogin = !!stores.UserInfoTable.localStore.token;
    if (!isLogin) {
        location.href = '/login';
    }

    if (isLogin && goLink) {
        if (!goLink) {
            return;
        }
        location.href = goLink;
    }
};
