import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/testEnvironmentApproveList',
        name: 'testEnvironmentApproveList',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-testEnvironmentApproveList" */ '@@/views/modules/dataLaboratory/modules/testEnvironmentApproveList')),
        meta: {
            title: '测试场景申请',
            intercept: intercept.route
        }
    },
    {
        path: '/testEnvironmentApproveList/testEnvironmentDetail',
        name: 'testEnvironmentApproveList.testEnvironmentDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-testEnvironmentApproveList-testEnvironmentDetail" */ '@@/views/modules/dataLaboratory/modules/testEnvironmentApprove')),
        meta: {
            title: '测试场景入驻查看',
            intercept: intercept.route,
        }
    },
    {
        path: '/testEnvironmentApproveList/testEnvironmentReview',
        name: 'testEnvironmentApproveList.testEnvironmentReview',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-testEnvironmentApproveList-testEnvironmentReview" */ '@@/views/modules/dataLaboratory/modules/testEnvironmentApprove')),
        meta: {
            title: '测试场景入驻编辑',
            intercept: intercept.route,
        }
    }
];
export default _Route;
