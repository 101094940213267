import { observable } from 'mobx';
import { Base } from './base';

interface ILocalStore {
    theme: string;
    userRoute: [];
    [k: string]: any;
}

interface ISiteInfo {
    localStore: ILocalStore;
    update: (params: any) => void;
    resetLocalStore: () => void;
    updateLocalStore: (param: ILocalStore) => void;
    cleanAllLocalStore: () => void;
}

const LOCAL_STORE: ILocalStore = {
    theme: '',
    userRoute: []
};

class SiteInfoTable extends Base implements ISiteInfo {

    public static selfClassName = 'SiteInfoTable';

    constructor() {
        super();
        this.initSyncLocalStorage(SiteInfoTable.selfClassName, this, LOCAL_STORE);
    }

    resetLocalStore() {
        this.localStore = LOCAL_STORE;
    }

    @observable localStore = LOCAL_STORE;
}

export { SiteInfoTable, ISiteInfo };
