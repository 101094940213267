import React from 'react';
import intercept from '@@/intercept';
import {IRouteItemConf} from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/dataLargeScreen',
        name: 'dataLargeScreen',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-login" */ '@@/views/modules/dataLargeScreen')),
        meta: {
            hidden: true,
            title: '数据要素流通运营驾驶舱',
            intercept: intercept.route
        }
    }
];
export default _Route;
