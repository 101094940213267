import login from './login';
import dataLargeScreen from './dataLargeScreen';
import dataServiceProvider from './dataServiceProvider';
import buyerCenter from './buyerCenter';
import sellerCenter from './sellerCenter';
import makeCenter from './makeCenter';
import operationCenter from './operationCenter';
import supplierCenter from './supplierCenter';
import dataProductMarket from './dataProductMarket';
import registered from './registered'; // 数商登记
import demandPlatform from './demandPlatform';
import publicityColumn from './publicityColumn';
import dataLaboratory from './dataLaboratory';
import order from './order';
import home from './home';
import testEnvironment from './testEnvironment';
import dataResourceManage from './dataResourceManage';
import userCenter from './userCenter';

//prettier-ignore
export default [
    ...login,
    ...dataLargeScreen,
    ...dataServiceProvider,
    ...buyerCenter,
    ...sellerCenter,
    ...makeCenter,
    ...operationCenter,
    ...supplierCenter,
    ...dataProductMarket,
    ...registered,
    ...demandPlatform,
    ...publicityColumn,
    ...dataLaboratory,
    ...order,
    ...home,
    ...testEnvironment,
    ...dataResourceManage,
    ...userCenter
];
