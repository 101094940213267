import { observable } from 'mobx';
import { Base } from './base';

interface ILocalStore {
    token?: string;
    loginType?: number;
    user?: {
        username?: string;
        companyName?: string;
        phoneNum?: string;
        contactName?: string;
        organizationType?: number | null;
        [k: string]: any;
    };

    [k: string]: any;
}

interface IUserInfo {
    localStore: ILocalStore;
    resetLocalStore: () => void;
    update: (params: any) => void;
    updateLocalStore: (param: ILocalStore) => void;
    cleanAllLocalStore: () => void;
}

const LOCAL_STORE: ILocalStore = {
    token: '',
    loginType: 1,
    user: {
        username: '',
        contactName: '',
        companyName: '',
        phoneNum: '',
        organizationType: null
    }
};

class UserInfoTable extends Base implements IUserInfo {
    public static selfClassName = 'UserInfoTable';

    constructor() {
        super();
        this.initSyncLocalStorage(
            UserInfoTable.selfClassName,
            this,
            LOCAL_STORE
        );
    }

    resetLocalStore() {
        this.localStore = LOCAL_STORE;
    }

    @observable localStore = LOCAL_STORE;
}

export { UserInfoTable, IUserInfo };
