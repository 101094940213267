import { IIntercept } from './intercept';
import { themeTask } from '@@services/themeTask';
import { areaTask } from '@common/services/areaTask.service';
import { setSiteInfo } from '@@services/user.service';

const intercept: IIntercept = () => {
    const theme = themeTask();
    const area = areaTask();

    setSiteInfo({ theme, area });

    return new Promise((resolve, reject) => {
        resolve(true);
        return;
    });
};

export default intercept;
