import Global from './global';
import { UserInfoTable, IUserInfo } from './table/userInfo.table';
import { SiteInfoTable, ISiteInfo } from './table/siteInfo.table';
import localStoreIntercept from './intercept/localStore.intercept';
import { ISystemInfo, SystemTable } from './table/system.table';

interface IStore {
    SiteInfoTable?: ISiteInfo;
    UserInfoTable?: IUserInfo;
    SystemTable?: ISystemInfo;
}

const stores: any = {};
const storeChunk = [Global, UserInfoTable, SiteInfoTable, SystemTable];

storeChunk.forEach((store) => {
    const storeName = store.selfClassName;
    stores[storeName] = new store();
    if (stores[storeName]['localStore']) {
        localStoreIntercept(stores[storeName], storeName);
    }
});

export { IStore, stores };
