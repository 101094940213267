import axios from 'axios';
import React from 'react';
import { stores, IStore } from '@@/store';
import { host } from '@@/config/host';
import { logout } from '@@services/user.service';

type IMethod = 'GET' | 'PUT' | 'DELETE' | 'POST';

interface ICommonOption {
    [key: string]: any;
}

const HttpService = (method: IMethod, url: string, data: ICommonOption = {}, headerMaps?: ICommonOption, otherParams?: ICommonOption): Promise<any> => {
    const Area = 'ningBo';
    const userInfoStore = (stores as IStore).UserInfoTable!.localStore;
    const Authorization = userInfoStore.token ? `Jztdata ${userInfoStore.token}` : '';
    let requestAddress = url.search('http') !== -1 ? url : `${host.SERVER_BASE_API}/market` + url;
    if (url.includes('/zlb/login')) {
        requestAddress = url.search('http') !== -1 ? url : `${host.SERVER_BASE_API}/usercenter` + url;
    }
    const requestData = data;

    return new Promise((resolve, reject) => {
        const axiosSendConfig: any = {
            method: method,
            withCredentials: false,
            url: requestAddress,
            headers: { Area, Authorization, ...headerMaps } || {},
            timeout: otherParams && otherParams.timeout ? otherParams.timeout : 30000
        };

        if (method === 'GET') {
            axiosSendConfig.params = requestData;
        } else {
            axiosSendConfig.data = JSON.stringify(requestData);
            axiosSendConfig.headers['content-type'] = 'application/json';
        }

        axios(axiosSendConfig)
            .then((response) => {
                const { code = '', data = {}, msg = '' } = response.data;

                if (code === 401 || code === 10005) {
                    logout();
                    return false;
                }

                if (code === 200) {
                    resolve([null, data || {}]);
                    return;
                }

                resolve([{ code, msg }, data]);
            })
            .catch((err) => {
                console.warn('err:', err);
                resolve([{}, null]);
            });
    });
};

export { HttpService };
