/* 个人中心 */
import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
import { FileSearchOutlined } from '@ant-design/icons';
type IReactLazyType =
  | React.ComponentClass<{}, any>
  | React.FunctionComponent<{}>

const _Route: IRouteItemConf[] = [
    {
        path: '/userCenter',
        name: 'userCenter',
        component: React.lazy<IReactLazyType>(() =>
            import('@@/views/modules/userCenter')
        ),
        meta: {
            title: '个人中心',
            intercept: intercept.route,
        },
    },
    {
        path: '/userCenter/msgInfo',
        name: 'userCenter.msgInfo',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-userCenter-msgInfo" */ '@@/views/modules/userCenter/modules/msgManage/msgInfo')),
        meta: {
            title: '消息详情',
            intercept: intercept.route
        }
    }
];
export default _Route;
