import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';

type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<any>;

const _Route: IRouteItemConf[] = [
    {
        path: '/dataLaboratory/dataApplyFor',
        name: '/dataLaboratory.dataApplyFor',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataResourceManage-dataApplyFor" */ '@@/views/modules/dataApplyFor')),
        meta: {
            title: '数据申请',
            intercept: intercept.route
        }
    },
    {
        path: '/dataLaboratory/dataApplyFor/applyForDetail',
        name: 'dataLaboratory.dataApplyFor.applyForDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataResourceManage-dataResourceList-applyForDetail" */ '@@/views/modules/dataApplyFor/applyForDetail')),
        meta: {
            title: '数据资源详情',
            intercept: intercept.route
        }
    }
];
export default _Route;
