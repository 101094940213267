/* 订单 */
import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/order',
        name: 'order',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-order" */ '@@/views/modules/order')),
        meta: {
            title: '订单中心',
            intercept: intercept.route
        }
    },
    {
        path: '/order/orderDetail',
        name: 'dataLaboratory.orderApprove',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-order-orderDetail" */ '@@/views/modules/order/modules/orderDetail')),
        meta: {
            title: '订单详情',
            intercept: intercept.route
        }
    }
];
export default _Route;
