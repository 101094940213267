/* 数据实验室 */
import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
import { FileSearchOutlined } from '@ant-design/icons';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/dataLaboratory',
        name: 'dataLaboratory',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataLaboratory" */ '@@/views/modules/dataLaboratory')),
        meta: {
            title: '数据实验室',
            intercept: intercept.route
        }
    },
    {
        path: '/dataLaboratory/handleApprove',
        name: 'dataLaboratory.orderApprove.orderApproveDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataLaboratory-handleApprove" */ '@@/views/modules/dataLaboratory/modules/handleApprove')),
        meta: {
            title: '测试场景入驻申请',
            intercept: intercept.route
        }
    },
    {
        path: '/dataLaboratory/testEnvironmentApprove',
        name: 'dataLaboratory.orderApprove.orderApproveDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataLaboratory-testEnvironmentApprove" */ '@@/views/modules/dataLaboratory/modules/testEnvironmentApprove')),
        meta: {
            title: '测试场景入驻申请',
            intercept: intercept.route
        }
    }
];
export default _Route;
