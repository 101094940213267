require('./utils/polyfill');
import React from 'react';
import App from './application/f2e-nb-data-service-site-web/app';
import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import 'dayjs/locale/zh-cn';


dayjs.locale('zh-cn');
dayjs.extend(isBetween);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
