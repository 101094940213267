import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType =
  | React.ComponentClass<{}, any>
  | React.FunctionComponent<{}>

const _Route: IRouteItemConf[] = [
    {
        path: '/demandPlatform',
        name: 'demandPlatform',
        component: React.lazy<IReactLazyType>(() =>
            import(/* webpackChunkName: "ROUTE-demandPlatform" */ '@@/views/modules/demandPlatform')
        ),
        meta: {
            title: '需求大厅',
            intercept: intercept.route,
        },
    },
    {
        path: '/demandPlatform/demandDetail',
        name: 'demandPlatform.demandDetail',
        component: React.lazy<IReactLazyType>(() =>
            import(/* webpackChunkName: "ROUTE-demandDetail" */ '@@/views/modules/demandPlatform/modules/demandDetail')
        ),
        meta: {
            title: '需求详情',
            intercept: intercept.route
        }
    },
    {
        path: '/demandPlatform/demandRelease',
        name: 'demandPlatform.demandRelease',
        component: React.lazy<IReactLazyType>(() =>
            import(/* webpackChunkName: "ROUTE-demandPlatform-demandRelease" */ '@@/views/modules/demandPlatform/modules/demandRelease')
        ),
        meta: {
            title: '发布需求',
            intercept: intercept.route
        } 
    },
    {
        path: '/demandPlatform/introductionPage',
        name: 'demandPlatform.introductionPage',
        component: React.lazy<IReactLazyType>(() =>
            import(/* webpackChunkName: "ROUTE--demandPlatform-introductionPage" */ '@@/views/modules/demandPlatform/modules/introductionPage')
        ),
        meta: {
            title: '需求大厅引导页',
            intercept: intercept.route
        }
    },
];
export default _Route;
