import { env } from '@common/env/index';
import useKeyGetUrlParam from '@common/utils/useKeyGetUrlParam.utils';
import { stores } from '@@/store';

const area = useKeyGetUrlParam('area');

export const isChangeArea = !!area;

// 添加全局区域
export const areaTask = () => {
    const JZT_AREA = env.appConfig.area;
    console.log('JZT_AREA:::', JZT_AREA);
    const storeArea = stores.SiteInfoTable.localStore.area;
    const isBetaReleaseArea = JZT_AREA || storeArea;
    const noBetaReleaseArea = storeArea || JZT_AREA;

    return area || JZT_AREA || storeArea;
};
