import { action, observable } from 'mobx';
import { Base } from './base';
import { LocalStoreAbstract } from '../abstract/localStore.abstract';

interface ILocalStore {}

interface IBreadcrumbItem {
    name: string;
    path: string;
    pushIndex: number;
    meta?: any;
    [key: string]: any;
}

interface ISystemInfo {
    breadcrumb: Array<any>;
    addBreadcrumb: (breadcrumb: IBreadcrumbItem) => void;
    createBreadcrumb: any;
    update: (params: any) => void;
    updateLocalStore: (param: ILocalStore) => void;
    cleanAllLocalStore: () => void;
}

const LOCAL_STORE: ILocalStore = {};

class SystemTable extends Base implements LocalStoreAbstract, ISystemInfo {
    public static selfClassName = 'SystemTable';
    constructor() {
        super();
        this.initSyncLocalStorage(SystemTable.selfClassName, this, LOCAL_STORE);
    }

    @observable public breadcrumb: Array<IBreadcrumbItem> = [];

    @action createBreadcrumb(breadcrumb: IBreadcrumbItem) {
        this.update({
            breadcrumb
        });
    }

    @action addBreadcrumb(breadcrumb: IBreadcrumbItem) {
        const newRouterList: Array<IBreadcrumbItem> = [...this.breadcrumb];
        newRouterList.splice(breadcrumb.pushIndex || 0, 0, {
            ...breadcrumb,
            meta: {
                hidden: false,
                intercept: [],
                title: breadcrumb.name
            }
        });

        this.createBreadcrumb(newRouterList as any);
    }

    @action clearBreadcrumb() {
        this.update({
            breadcrumb: []
        });
    }

    @observable localStore = LOCAL_STORE;
}

export { SystemTable, ISystemInfo };
