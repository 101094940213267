import React, { Suspense } from 'react';
import Routes from '@@/views/.route';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/es/locale/zh_CN';
import globalRouteMiddleware from '@@/middleware/global.route';
import { stores } from '@@/store';
import { Provider } from 'mobx-react';
import { ConfigProvider, App as AntdApp } from 'antd';
import history from '@common/services/routeHistory.service';
import { themeDark } from '@@services/themeTask';
import useKeyGetUrlParam from '@common/utils/useKeyGetUrlParam.utils';
import 'animate.css';

import 'tailwindcss/tailwind.css';

import './global.scss';

import { Router, Switch, Route } from 'react-router-dom';
import { useMount } from 'ahooks';
import MsgSocket from '@common/common_view/msgSocket';

function addOrgToUrl(url: string, key: string, val: string) {
    if (url.indexOf(key) > -1) {
        const re = eval('/(' + key + '=)([^&]*)/gi');
        url = url.replace(re, key + '=' + val);
    } else {
        const paraStr = key + '=' + val;
        const idx = url.indexOf('?');
        if (idx < 0) {
            url += '?';
        } else if (idx >= 0 && idx !== url.length - 1) {
            url += '&';
        }
        url = url + paraStr;
    }
    return url;
}

const App = () => {
    const theme = stores.SiteInfoTable.localStore.theme;

    const useKeyGetUrlParam2 = (key: string, url: string) => {
        const urlSearch = new URL(url);
        return urlSearch.searchParams.get(key);
    };

    useMount(() => {
        // const ticketId = useKeyGetUrlParam('ticketId');
        // const loginType = useKeyGetUrlParam('loginType');
        const ticketId = useKeyGetUrlParam('ticketId');
        let redirectUrl = useKeyGetUrlParam('redirectUrl') || '';
        redirectUrl = redirectUrl.replace('?', '&');
        redirectUrl = redirectUrl.replace('/login&', '/login?');
        console.log('redirectUrl=', redirectUrl);
        if (redirectUrl) {
            const loginType = useKeyGetUrlParam2('loginType', redirectUrl);
            console.log('ticketId=', ticketId);
            console.log('loginType=', loginType);
            console.log('redirectUrl111=', redirectUrl);
            if (ticketId && loginType && redirectUrl) {
                redirectUrl = addOrgToUrl(redirectUrl, 'ticketId', ticketId);
                redirectUrl = addOrgToUrl(redirectUrl, 'loginType', loginType);
                console.log('redirectUrl222=', redirectUrl);
                location.href = redirectUrl;
            }
        }
    });

    return (
        <Router history={history}>
            <Provider store={stores}>
                <ConfigProvider locale={zhCN} theme={theme === 'dark' ? themeDark : {}}>
                    <AntdApp className="h-full">
                        <Switch>
                            <Suspense fallback={<></>}>
                                {Routes.map((routeItem) => {
                                    return <Route exact key={routeItem.path} path={routeItem.path} component={globalRouteMiddleware(routeItem.component as any, routeItem)} />;
                                })}
                            </Suspense>
                        </Switch>
                        {/* <MsgSocket /> */}
                    </AntdApp>
                </ConfigProvider>
            </Provider>
        </Router>
    );
};

export default App;
