import React from 'react';
import { withRouter } from 'react-router';
import './index.scoped.scss';
import logo from '@@/assets/images/logo-sh-1.png';
import { host } from '@@/config/host';
import { Link } from 'react-router-dom';

const FooterBar = () => {
    return (
        <div
            className="footer-bar"
            style={{
                background: `url(/public/nb-footer-bg.png)`,
                backgroundSize: '100% 100%'
            }}
        >
            <div className="layout flexbt">
                <div className="left flexbt">
                    <ul>
                        <li className="tr">
                            <a>入驻指南</a>
                        </li>
                        <li className="td">
                            <a>授权运营单位入驻指南</a>
                        </li>
                        <li className="td">
                            <a>社会加工单位入驻指南</a>
                        </li>
                        <li className="td">
                            <a>供应商入驻指南</a>
                        </li>
                    </ul>
                    <ul>
                        <li className="tr">
                            <a>服务支持</a>
                        </li>
                        <li className="td">
                            <a>产品购买指南</a>
                        </li>
                        <li className="td">
                            <Link to="/demandPlatform/introductionPage">需求交付指南</Link>
                        </li>
                        <li className="td">
                            <a>资源提供指南</a>
                        </li>
                    </ul>
                    <ul>
                        <li className="tr">
                            <a>服务协议</a>
                        </li>
                        <li className="td">
                            <a>数商入驻协议</a>
                        </li>
                        <li className="td">
                            <a>产品购买协议</a>
                        </li>
                        <li className="td">
                            <a>需求交付协议</a>
                        </li>
                        <li className="td">
                            <a>平台注册协议</a>
                        </li>
                    </ul>
                </div>
                <div className="right">
                    <img className="logo" src={logo} />
                    <div className="item flexst">
                        <div className="label">服务热线：</div>
                        <div className="value"></div>
                    </div>
                    <div className="item flexst">
                        <div className="label">服务邮箱：</div>
                        <div className="value"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(FooterBar);
