import React            from 'react';
import {IRouteItemConf} from '@common/interface/route.interface';
import intercept        from '@@/intercept';

import routes from '../routes';

const route: IRouteItemConf[] = [
  ...routes
];

export default route;
