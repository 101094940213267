import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/dataServiceProvider',
        name: 'dataServiceProvider',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider" */ '@@/views/modules/dataServiceProvider')),
        meta: {
            title: '数据服务商',
            intercept: intercept.route
        }
    },
    {
        path: '/dataServiceProvider/releaseRequire',
        name: 'dataServiceProvider.releaseRequire',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-releaseRequire" */ '@@/views/modules/dataServiceProvider/modules/releaseRequire')),
        meta: {
            title: '发布需求',
            intercept: intercept.route
        }
    },
    {
        path: '/dataServiceProvider/providerDetail',
        name: 'dataServiceProvider.providerDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider" */ '@@/views/modules/dataServiceProvider/modules/providerDetail')),
        meta: {
            title: '服务商详情',
            intercept: intercept.route
        }
    },
    {
        path: '/dataServiceProvider/apiProductDetail',
        name: 'dataServiceProvider.apiProductDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider" */ '@@/views/modules/dataServiceProvider/modules/dataProductDetail/apiProductDetail')),
        meta: {
            title: '数据产品详情(API)',
            intercept: intercept.route
        }
    },
    {
        path: '/dataServiceProvider/dataPacketProductDetail',
        name: 'dataServiceProvider.dataPacketProductDetail',
        component: React.lazy<IReactLazyType>(() => import(/* webpackChunkName: "ROUTE-dataServiceProvider" */ '@@/views/modules/dataServiceProvider/modules/dataProductDetail/dataPacketDetail')),
        meta: {
            title: '数据产品详情(数据包)',
            intercept: intercept.route
        }
    },
];
export default _Route;
