import { env } from '@common/env/index';
import useKeyGetUrlParam from '@common/utils/useKeyGetUrlParam.utils';
import { stores } from '@@/store';

// 获取主题
export const themeTask = () => {
    const theme = useKeyGetUrlParam('theme');
    const JZT_THEME = env.appConfig.theme;
    const storeTheme = stores.SiteInfoTable.localStore.theme;

    return theme || storeTheme || JZT_THEME;
};

// 深色配置
export const themeDark = {
    token: {
        // colorPrimary: '#D8D8D8',
        // colorInfo: '#fff',
        colorInfoActive: '#fff',
        colorText: '#fff',
        colorTextBase: '#fff',
        colorBgBase: 'rgba(23,45,64,0.7)',
        borderRadius: 6,
        // 控制内容区域背景色在鼠标悬停时的样式
        colorFillContentHover: '#D8D8D8',
        // 控制元素替代背景色
        colorFillAlter: '#2F485E',
        // 控制内容区域的背景色
        colorFillContent: '#D8D8D8',
        // 控制容器在禁用状态下的背景色
        // colorBgContainerDisabled: '#D8D8D8',
        // 控制文本在悬停状态下的背景色
        // colorBgTextHover: '#D8D8D8',
        // 控制文本在激活状态下的背景色
        colorBgTextActive: '#D8D8D8',
        // 控制占位文本的颜色
        colorTextPlaceholder: '#D8D8D8',
        // 控制标题字体颜色
        colorTextHeading: '#fff',
        // 控制文本标签字体颜色
        colorTextLabel: 'white',
        colorTextLightSolid: 'white',
        // 控制文本描述字体颜色
        colorTextDescription: '#D8D8D8',
        // 控制页面元素高亮时的颜色
        colorHighlight: 'red',
        // controlItemBgHover: 'red',
        controlItemBgActive: '#1677ff',
        colorBorder: '#2F485E',
        // controlItemBgActiveHover: 'red',
        // controlTmpOutline: 'red',
        // 控制元素背景边框的颜色
        // colorBorderBg: 'red',
        // 用于作为分割线的颜色，此颜色和 colorBorderSecondary 的颜色一致，但是用的是透明色
        // colorSplit: 'red',
        // 控制禁用状态下的字体颜色
        // colorTextDisabled: 'red',
        // 控制带背景色的文本，例如 Primary Button 组件中的文本高亮颜色
        // colorTextLightSolid: 'red',
        // 控制弱操作图标的颜色，例如 allowClear 或 Alert 关闭按钮
        // colorIcon: 'red',
        // 控制超链接悬浮时的颜色
        // colorLinkHover: 'red',
        // 控制超链接被点击时的颜色
        // colorLinkActive: 'red',
        // 控制输入组件的外轮廓线颜色
        // controlOutline: 'red',
        // 控制输入组件警告状态下的外轮廓线颜色
        // colorWarningOutline: 'red',
        // colorErrorOutline: 'red'
    }
};
