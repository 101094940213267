import React from 'react';
import intercept from '@@/intercept';
import { IRouteItemConf } from '@common/interface/route.interface';
type IReactLazyType = React.ComponentClass<{}, any> | React.FunctionComponent<{}>;

const _Route: IRouteItemConf[] = [
    {
        path: '/publicityColumn/dataCompanyDetails',
        name: 'dataCompanyDetails',
        component: React.lazy<IReactLazyType>(() => import('@@/views/modules/publicityColumn/dataCompanyDetails')),
        meta: {
            title: '数商详情',
            intercept: intercept.route
        }
    },
    {
        path: '/publicityColumn/dataProductDetails',
        name: 'dataProductDetails',
        component: React.lazy<IReactLazyType>(() => import('@@/views/modules/publicityColumn/dataProductDetails')),
        meta: {
            title: '数据产品详情',
            intercept: intercept.route
        }
    }
];
export default _Route;
