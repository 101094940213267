interface IProcessEnv {
    NODE_ENV: TNodeEnv;
    APP_ENV: string;
    APP_NAME: string;
    APP_CONFIG?: {
        AREA: string;
        THEME: string;
        ADMIN_TYPE: string;
    };
}

const processEnv: IProcessEnv = process.env as any;

type TNodeEnv = 'development' | 'production';
type TAppEnv =
    | 'dev'
    | 'debug'
    | 'debug2'
    | 'test'
    | 'tes2'
    | 'beta'
    | 'release';

interface IEnv {
    nodeEnv: TNodeEnv;
    appEnv: TAppEnv;
    appName: string;
    appConfig: {
        area: string;
        theme: string;
        adminType: string;
    };
    isDev: boolean;
    isBetaRelease: boolean;
    isWebview: boolean;
}

const APP_ENV = String(processEnv.APP_ENV) as TAppEnv;

export const env: IEnv = {
    nodeEnv: String(processEnv.NODE_ENV) as TNodeEnv,
    appEnv: String(processEnv.APP_ENV) as TAppEnv,
    appName: String(processEnv.APP_NAME),
    appConfig: {
        area: String(processEnv.APP_CONFIG?.AREA || ''),
        theme: String(processEnv.APP_CONFIG?.THEME || ''),
        adminType: String(processEnv.APP_CONFIG?.ADMIN_TYPE || '')
    },
    isDev: Boolean(process.env.NODE_ENV === 'development'),
    isBetaRelease: APP_ENV === 'beta' || APP_ENV === 'release',
    isWebview:
        ((window as any).webkit &&
            (window as any).webkit?.messageHandlers &&
            (window as any).webkit?.messageHandlers?.javascriptBridge &&
            (window as any).webkit?.messageHandlers?.javascriptBridge
                ?.postMessage) ||
        (window as any).javascriptBridge ||
        false
};
